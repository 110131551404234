@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.infoMessageContainer {
  margin-top: 30px;

  @include max-screen(992px) {
    margin-bottom: 20px;
  }

  @include max-screen(767px) {
    margin: 20px 10px 15px;
  }
}
