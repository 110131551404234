@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Mixins
@mixin remove-default-button {
  background-color: transparent;
  border-style: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

@mixin placeholder-color($color) {
  &:-moz-placeholder {
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &.placeholder {
    color: $color;
  }
}

@mixin animation($properties) {
  animation: $properties;
}

@mixin fadeIn($duration, $delay: 0s) {
  animation: fadeIn $duration ease-in $delay;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin box-sizing($border-box) {
  box-sizing: $border-box;
}

@mixin box-shadow($properties) {
  box-shadow: $properties;
}

@mixin inline-block {
  display: inline-block;
}

@mixin opacity($value) {
  opacity: $value;
}

@mixin color-opacity($property, $color, $opacity: 0.5, $extraParams...) {
  #{$property}: $color $extraParams;
  #{$property}: rgba($color, $opacity) $extraParams;
}

@mixin transition($properties...) {
  transition: $properties;
}

@mixin transform($properties) {
  transform: $properties;
}

@mixin transform-origin($properties) {
  transform-origin: $properties;
}

@mixin vertical-gradient($from, $to) {
  background: $from;
  background: linear-gradient(to bottom, $from, $to);
}

@mixin no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

@mixin user-select($prop: none) {
  user-select: $prop;
}

// Taiko Mixins
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin opac-hover {
  i {
    @include transition(opacity 100ms ease-in);

    opacity: 0.3;
    will-change: opacity;
  }

  &:hover {
    i {
      opacity: 0.7;
    }
  }
}

@mixin maisonneue-book {
  font-family: $base-font-family;
}

// Mono: To be deprecated
@mixin maisonneue-mono {
  font-family: $accent-font-family;
}

@mixin maisonneue-demi {
  font-family: $secondary-font-family;
}

@mixin flexbox-display {
  display: flex;
}

@mixin flexbox-display-important {
  display: flex !important;
}

@mixin flexbox-direction($orientation) {
  flex-direction: $orientation;

  @if $orientation == 'row' or $orientation == 'row-reverse' {
    -webkit-box-orient: horizontal;
  } @else if $orientation == 'column' or $orientation == 'column-reverse' {
    -webkit-box-orient: vertical;
  }

  @if $orientation == 'row' or $orientation == 'column' {
    -webkit-box-direction: normal;
  } @else if $orientation == 'column-reverse' or $orientation == 'row-reverse' {
    -webkit-box-direction: reverse;
  }
}

@mixin flexbox-align($orientation) {
  align-items: $orientation;
}

@mixin flexbox-justify($justification) {
  justify-content: $justification;
}

@mixin flexbox-grow($size) {
  flex-grow: $size;
}

// Fonts
@mixin h1-font-book {
  @include maisonneue-book;
  font-size: rem(36);
  line-height: rem(50);
  letter-spacing: rem(0.25);
}

@mixin h1-font-demi {
  @include maisonneue-demi;
  font-size: rem(36);
  line-height: rem(50);
  letter-spacing: rem(0.25);
}

@mixin h2-font-book {
  @include maisonneue-book;
  font-size: rem(28);
  line-height: rem(38);
  letter-spacing: rem(0.25);
}

@mixin h2-font-demi {
  @include maisonneue-demi;
  font-size: rem(28);
  line-height: rem(38);
  letter-spacing: rem(0.25);
}

@mixin h3-font-demi {
  @include maisonneue-demi;
  font-size: rem(21);
  line-height: rem(29);
  letter-spacing: rem(0.25);
  color: $charcoal;
}

@mixin h3-font-book {
  @include maisonneue-book;
  font-size: rem(21);
  line-height: rem(29);
  letter-spacing: rem(0.25);
}

@mixin subheader-font-demi {
  @include maisonneue-demi;
  font-size: rem(18);
  line-height: rem(28);
  letter-spacing: rem(0.25);
  color: $charcoal;
}

@mixin subheader-font-book {
  @include maisonneue-book;
  font-size: rem(18);
  line-height: rem(28);
  letter-spacing: rem(0.25);
}

@mixin body-font {
  @include maisonneue-book;
  font-size: rem(16);
  line-height: rem(28);
  letter-spacing: rem(0.25);
}

@mixin body-font-2 {
  @include maisonneue-book;
  font-size: rem(14);
  line-height: rem(25);
  letter-spacing: rem(0.25);
}

@mixin body-font-3 {
  @include maisonneue-demi;
  font-size: rem(14);
  line-height: rem(25);
  letter-spacing: rem(0.25);
}

@mixin body-font-4 {
  @include maisonneue-book;
  font-size: rem(12);
  line-height: rem(20);
  letter-spacing: rem(0.25);
}

@mixin body-font-5 {
  @include maisonneue-demi;
  font-size: rem(12);
  line-height: rem(20);
  letter-spacing: rem(0.25);
}

@mixin body-font-6 {
  @include maisonneue-book;
  font-size: rem(11);
  line-height: rem(15);
  letter-spacing: rem(0.25);
}

@mixin info-small-font {
  @include maisonneue-book;
  font-size: rem(9);
  line-height: rem(15);
  letter-spacing: rem(0.25);
}

@mixin button-cta-font {
  @include maisonneue-demi;
  font-size: rem(14);
  line-height: rem(25);
  letter-spacing: rem(2);
}

@mixin caption-font {
  @include maisonneue-demi;
  font-size: rem(12);
  line-height: rem(20);
  letter-spacing: rem(2);
}

@mixin link-primary {
  @include button-cta-font;
  border-bottom: 1px solid $link-color-light;
  color: $link-color-light;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    border-bottom: 1px solid $text-color-primary;
    color: $text-color-primary;
  }
}

@mixin link-secondary {
  @include button-cta-font;
  border-bottom: 1px solid $text-color-secondary;
  color: $text-color-secondary;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    border-bottom: 1px solid $text-color-primary;
    color: $text-color-primary;
  }
}

@mixin zigzag(
  $outlineColor: white,
  $base: 2,
  $backgroundSize: null,
  $height: null,
  $topOffset: null,
  $leftOffset: null,
  $marginLeft: null
) {
  @if $backgroundSize == null {
    $backgroundSize: #{$base * 2}px #{$base * 4}px;
  }

  @if $height == null {
    $height: #{$base * 2}px;
  }

  @if $topOffset == null {
    $topOffset: -#{$base * 2}px;
  }

  @if $topOffset == null {
    $topOffset: -#{$base * 2}px;
  }

  @if $leftOffset == null {
    $leftOffset: #{$base * 2}px;
  }

  @if $marginLeft == null {
    $marginLeft: -#{$base * 2}px;
  }

  & {
    .zigzag:before,
    .zigzag:after,
    &:after,
    &:before {
      background: linear-gradient(
          -45deg,
          $outlineColor #{$base * 2}px,
          red #{$base * 2}px,
          blue #{$base * 2}px,
          transparent 0
        ),
        linear-gradient(45deg, $outlineColor #{$base * 2}px, transparent 0);
      background-position: left top;
      background-repeat: repeat-x;
      background-size: $backgroundSize;
      content: '';
      display: block;
      height: $height;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
      outline: 1px solid transparent;
    }

    &:before {
      top: $topOffset;
      transform: rotateZ(180deg);
      transform-origin: bottom center;
    }

    &:after {
      bottom: 0;
    }

    .zigzag:before {
      top: 0;
      left: $leftOffset;
      transform: rotateZ(90deg);
      transform-origin: left top;
    }

    .zigzag:after {
      top: 0;
      left: 100%;
      margin-left: $marginLeft;
      transform: rotateZ(90deg) rotateX(180deg);
      transform-origin: left top;
    }
  }
}

// Everything below is to be deprecated
@mixin mx-font-h1 {
  font-family: $secondary-font-family;
  font-size: rem(28);
  line-height: rem(42);
  letter-spacing: rem(2.19);
  text-transform: uppercase;
}

@mixin mx-font-h2 {
  font-family: $base-font-family;
  font-size: rem(32);
  line-height: rem(44);
}

@mixin mx-font-h1-alt {
  font-family: $secondary-font-family;
  font-size: rem(21);
  line-height: rem(31);
  letter-spacing: rem(2.19);
  text-transform: uppercase;
}

@mixin mx-font-h2-alt {
  font-family: $base-font-family;
  font-size: rem(28);
  line-height: rem(39);
}

@mixin mx-font-title {
  font-family: $secondary-font-family;
  font-size: rem(14);
  line-height: rem(20);
  letter-spacing: rem(2.5);
  text-transform: uppercase;
}

@mixin mx-font-subheading {
  font-family: $base-font-family;
  font-size: rem(21);
  line-height: rem(29);
}

@mixin mx-font-subheading-2 {
  font-family: $base-font-family;
  font-size: rem(18);
  line-height: rem(23);
}

@mixin mx-font-subheading-3 {
  font-family: $base-font-family;
  font-size: rem(13);
  line-height: rem(23);
}

@mixin mx-font-body {
  font-family: $base-font-family;
  font-size: rem(16);
  line-height: rem(28);
  letter-spacing: rem(0.64);
}

@mixin mx-font-body-2 {
  font-family: $base-font-family;
  font-size: rem(14);
  line-height: rem(25);
  letter-spacing: rem(0.56);
}

@mixin mx-font-body-3 {
  font-family: $base-font-family;
  font-size: rem(10);
  line-height: rem(19);
  letter-spacing: rem(0.4);
}

@mixin mx-font-caption {
  font-family: $accent-font-family;
  font-size: rem(14);
  line-height: rem(20);
}

@mixin mx-font-quote {
  font-family: $accent-font-family;
  font-size: rem(18);
  line-height: rem(34);
}

@mixin mx-font-quote-2 {
  font-family: $accent-font-family;
  font-size: rem(15);
  line-height: rem(32);
}

@mixin mx-font-quote-caption {
  font-family: $base-font-family;
  font-size: rem(14);
  line-height: rem(20);
  letter-spacing: rem(1);
}

@mixin mx-font-quote-caption-title {
  @include mx-font-quote-caption;
  font-weight: bold;
}

@mixin mx-font-product-caption {
  font-family: $base-font-family;
  font-size: rem(12);
  line-height: rem(28);
  letter-spacing: rem(0.48);
}

@mixin mx-number--small {
  font-family: $accent-font-family;
  font-size: rem(14);
  line-height: rem(20);
  text-transform: none;
}

@mixin mx-number--big {
  font-family: $accent-font-family;
  font-size: rem(24);
  line-height: rem(32);
  text-transform: none;
}

@mixin mx-link {
  font-family: $secondary-font-family;
  font-size: rem(14);
  letter-spacing: rem(2.5);
  line-height: rem(20);
  text-decoration: none;
  text-transform: uppercase;
}

@mixin mx-link--primary {
  border-bottom: 1px solid $mx-color-primary;
  color: $mx-color-primary;
  font-family: $secondary-font-family;
  font-size: rem(14);
  letter-spacing: 2.5px;
  line-height: rem(20);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    border-bottom: 1px solid $mx-color-black;
    color: $mx-color-black;
  }
}

@mixin mx-link--primary-dark {
  border-bottom: 1px solid $mx-color-primary-dark;
  color: $mx-color-primary-dark;
  font-family: $secondary-font-family;
  font-size: rem(14);
  letter-spacing: 2.5px;
  line-height: rem(20);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    border-bottom: 1px solid $mx-color-black;
    color: $mx-color-black;
  }
}

@mixin mx-link--secondary {
  border-bottom: 1px solid $mx-color-secondary;
  color: $mx-color-secondary;
  font-family: $secondary-font-family;
  font-size: rem(14);
  letter-spacing: 2.5px;
  line-height: rem(20);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    border-bottom: 1px solid $mx-color-black;
    color: $mx-color-black;
  }
}

// Styleguide aliases
@mixin mx-font-1 {
  @include mx-font-h2;
}

@mixin mx-font-2 {
  @include mx-font-h1;
}

@mixin mx-font-3 {
  @include mx-font-subheading;
}

@mixin mx-font-4 {
  @include mx-font-title;
}

@mixin mx-font-5 {
  @include mx-font-body;
}

@mixin mx-font-6 {
  @include mx-font-body-2;
}

@mixin mx-font-7 {
  @include mx-font-quote-caption;
}

@mixin placeholder-animation {
  animation: 1.5s ease-in-out 0.5s infinite normal none running pulsate;

  @keyframes pulsate {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
