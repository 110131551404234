@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.loadMore {
  span {
    display: block;
  }
}

.pageButton {
  @include mx-number--big;
  color: $charcoal;
  border: 1px solid transparent;
  width: 2em;
  height: 2em;
  text-align: center;
  margin: 3px;
  padding-top: 5px;

  &:hover,
  &:active,
  &:focus {
    background-color: $charcoal;
    color: $white;
  }

  &.activePageButton {
    cursor: default;
    background-color: $white;
    border: 1px solid $charcoal;
    color: $charcoal;
  }

  & > span {
    font-size: 0.6em;
    position: relative;

    &:after {
      content: '.';
      position: absolute;
      bottom: -8px;
    }
  }
}
