@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $mx-color-primary-dark;

  h1 {
    @include h2-font-demi;
    color: $mx-color-white !important;
    margin: 0;
    padding: 26px 0;
    text-align: center;

    @include max-screen(992px) {
      @include h3-font-demi;
      padding: 30px 0;
    }

    @include max-screen(767px) {
      padding: 30px 10px;
    }
  }
}

.logoContainer {
  padding: 37px 0 27px;
  margin-bottom: 20px;
  text-align: center;
}

.logo {
  display: inline-block;

  svg {
    display: block;
    height: 36px;

    path {
      fill: $mx-color-white;
    }

    polygon {
      fill: $mx-color-white;
    }
  }
}
