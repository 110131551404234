// DO NOT USE GLOBALLY
$maisonneue-book: 'MaisonNeue-Book';
$maisonneue-mono: 'MaisonNeue-Mono';
$maisonneue-demi: 'MaisonNeue-Demi';

$base-font-style: normal;
$base-font-line-height: 32px;
$base-font-size: 16px;
$base-font-size-mobile: 12px;
$base-font-family: #{$maisonneue-book}, 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$secondary-font-family: #{$maisonneue-demi}, 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$accent-font-family: #{$maisonneue-mono}, 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$std-font-letter-spacing: 0.33px;

// Media-query, customize to your own needs
$mobile: 'screen and (max-width: 640px)';
$desktop: 'screen and (min-width: 641px)';
$ipad-global: 'screen and (min-width: 768px) and (max-width: 1024px)';
$ipad-portrait: 'screen and (min-width: 768px) and (max-width: 768px)';
$ipad-landscape: 'screen and (min-width: 1024px) and (max-width: 1024px)';

// Configurator sidebar
$configurator-sidebar-width: 80px;

$steel_stainless: url($sprite) 0 -725px no-repeat;
$steel_stainless_disabled: url($sprite) -75 -725px no-repeat;
$oak: url($sprite) 0 -800px no-repeat;
$oak_disabled: url($sprite) -75px -800px no-repeat;
$beech: url($sprite) -150px -800px no-repeat;
$beech_disabled: url($sprite) -225px -800px no-repeat;
$walnut: url($sprite) 0 -875px no-repeat;
$walnut_disabled: url($sprite) -75px -875px no-repeat;
$oak_wenge_stain: url($sprite) -150px -875px no-repeat;
$oak_wenge_stain_disabled: url($sprite) -225px -875px no-repeat;

// http://www.ralcolor.com/
$white_9003: #f3f3f3;
$yellow_1021: #f6b600;
$blue_5009: #225f78;
$red_3016: #b52b06;
$grey_7037: #77786f;
$black_9004: #222223;
$grey_7024: #464b54;
$steel_black_9004: #000000;
$veneered_white: #f3f3f3;
$veneered_yellow: #f6b600;
$veneered_blue: #225f78;
$veneered_red: #b52b06;
$veneered_grey: #77786f;
$veneered_black: #222223;
$veneered_anthracite: #464b54;
