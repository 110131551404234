@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  .expandingButton {
    max-width: 44px;
    overflow: hidden;
    transition: max-width 200ms ease-in;
    padding: 6px 5px;
  }
  @include min-screen(768px) {
    &:hover {
      cursor: pointer;

      .expandingButton {
        max-width: 300px;

        span {
          display: inline-block;
        }
      }
    }
  }
}
.ctaButton {
  width: auto !important;
  height: 42px;
  position: absolute;
  top: 10px;
  right: 10px;
  white-space: nowrap;

  span {
    display: none;
    transition: display 3s linear;
  }
}

.title {
  @include maisonneue-book;
  font-size: rem(14);
  line-height: rem(25);
  letter-spacing: rem(0.56);
  color: $charcoal;

  @include max-screen(1024px) {
    font-size: rem(12);
    line-height: rem(28);
    letter-spacing: rem(0.48);
  }
}

.subtitle {
  @include maisonneue-book;
  font-size: rem(12);
  line-height: rem(28);
  letter-spacing: rem(0.48);
  color: $ash;
  margin: 0 !important;

  @include max-screen(992px) {
    font-size: rem(10);
    line-height: rem(19);
    letter-spacing: rem(0.4);
  }
}

.badge {
  background-color: $light-pink;
  padding: 5px 10px;
  font-size: rem(12);
  line-height: rem(20);
  letter-spacing: rem(0.56);
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 25px;
  height: 30px;
  vertical-align: middle;

  span {
    color: $charcoal;
  }

  svg {
    * {
      stroke: $charcoal;
    }
  }
}

.mobileLayout {
  .title {
    font-size: rem(14);
    line-height: rem(25);
    letter-spacing: rem(0.56);
  }

  .customizeIconCta {
    top: 5px;
    right: 5px;
  }

  .badge {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 5px;
  }
}
