@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.title {
  @include maisonneue-book;
  font-size: rem(21);
  line-height: rem(29);
  letter-spacing: rem(0);
  color: $charcoal;
}

.text {
  @include maisonneue-book;
  font-size: rem(14);
  line-height: rem(25);
  letter-spacing: rem(0.56);
  color: $ash;
  margin: 7px 0 29px 0;
}

.label {
  color: $neutral;
}

.button {
  text-align: center;
  margin-top: 6px;

  button {
    padding: 11px 60px !important;
  }
}

.modalHeader {
  :global {
    .Modal__headerText {
      padding: 0;
    }

    .Modal__closeButton {
      svg path {
        stroke: $white !important;
        stroke-width: 6px;
      }
    }
  }
}

.modalContent {
  padding: 23px 88px 30px !important;

  @include max-screen(767px) {
    padding: 18px 19px 40px !important;
  }
}
