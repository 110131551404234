//  Author: Avraam Mavridis
//  Github: https://github.com/AvraamMavridis
//  Email : avr.mav@gmail.com
@mixin screen($minimum_resolution, $maximum_resolution) {
  @media screen and (min-width: $minimum_resolution) and (max-width: $maximum_resolution) {
    @content;
  }
}

@mixin max-screen($resolution) {
  @media screen and (max-width: $resolution) {
    @content;
  }
}

@mixin min-screen($resolution) {
  @media screen and (min-width: $resolution) {
    @content;
  }
}

// The width/height ratio is 3/2
@mixin wide-screen-ratio {
  @media all and (min-aspect-ratio: 3 / 2) {
    @content;
  }
}

@mixin screen-height($minimum_resolution, $maximum_resolution) {
  @media screen and (min-height: $minimum_resolution) and (max-height: $maximum_resolution) {
    @content;
  }
}

@mixin max-screen-height($resolution) {
  @media screen and (max-height: $resolution) {
    @content;
  }
}

@mixin min-screen-height($resolution) {
  @media screen and (min-height: $resolution) {
    @content;
  }
}

@mixin orientation($orientation) {
  @media (orientation: $orientation) {
    @content;
  }
}

// Iphone 5
@mixin iphone5($orientation: all) {
  $device_minimum_width: 320px;
  $device_maximum_width: 568px;
  $device_pixel_ratio: 2;
  $deviceAspectRatio: '40/71';

  @if $orientation == all {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) and (device-aspect-ratio: $deviceAspectRatio) {
      @content;
    }
  } @else {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) and (device-aspect-ratio: $deviceAspectRatio) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// Iphone 6
@mixin iphone6($orientation: all) {
  $device_minimum_width: 375px;
  $device_maximum_width: 667px;
  $device_pixel_ratio: 2;

  @if $orientation == all {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) {
      @content;
    }
  } @else {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// Iphone 6 Plus
@mixin iphone6-plus($orientation: all) {
  $device_minimum_width: 414px;
  $device_maximum_width: 736px;
  $device_pixel_ratio: 3;

  @if $orientation == all {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) {
      @content;
    }
  } @else {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// Ipads
@mixin ipad($orientation: all) {
  $device_minimum_width: 768px;
  $device_maximum_width: 1024px;

  @if $orientation == all {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) {
      @content;
    }
  } @else {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// Ipads retina
@mixin ipad-retina($orientation: all) {
  $device_minimum_width: 768px;
  $device_maximum_width: 1024px;
  $device_pixel_ratio: 2;

  @if $orientation == all {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) {
      @content;
    }
  } @else {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (-webkit-device-pixel-ratio: $device_pixel_ratio) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// Mobile
@mixin mobile($orientation: all) {
  $device_minimum_width: 0px;
  $device_maximum_width: 767px;

  @if $orientation == all {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) {
      @content;
    }
  } @else {
    @media only screen and (min-device-width: $device_minimum_width) and (max-device-width: $device_maximum_width) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

// Tablet
@mixin tablet($orientation: all) {
  @if $orientation == all {
    @media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
      @content;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      @content;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      @content;
    }

    @media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
      @content;
    }
  } @else {
    @media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: #{$orientation}) {
      @content;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: #{$orientation}) {
      @content;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: #{$orientation}) {
      @content;
    }

    @media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: #{$orientation}) {
      @content;
    }
  }
}

@mixin small-screen() {
  @media screen and (max-width: 500px) and (orientation: portrait),
    screen and (max-width: 767px) and (orientation: landscape) {
    @content;
  }
}
