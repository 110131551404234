@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.wrapper {
  background-color: $paper;

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  &.transparentBg {
    background-color: transparent;
  }
}

.container {
  margin: auto;
  max-width: 1220px;
  color: $text-color-primary;

  a {
    color: $text-color-primary;
  }
}

.hideOnMobile {
  @include max-screen(767px) {
    display: none;
  }
}

.row {
  display: flex;
  justify-content: space-around;

  @include max-screen(992px) {
    justify-content: space-between;
    padding: 0 15px;
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 0;
  }
}

.point {
  padding: 10px 30px;

  &:focus {
    text-decoration: none;
  }

  @include max-screen(992px) {
    padding: 10px 0px;
  }

  @media screen and (max-width: 767px) {
    padding: 15px;
    &:not(:last-child) {
      border-bottom: 1px solid $light-ice;
    }
  }
}

.withText {
  flex-basis: 33.33%;
}

.placeholder {
  .title,
  .text {
    color: transparent;
    background-color: $white;
  }

  .icon {
    svg {
      [fill='none'] {
        stroke: $white;
        stroke-width: 10;
      }
    }
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  margin: 0 auto;

  @include max-screen(767px) {
    text-align: left;
  }
}

.title {
  display: inline-block;

  @include body-font-3;
  white-space: pre;
  color: $charcoal;

  @include max-screen(980px) {
    white-space: normal;
  }
}

.icon {
  display: inline-block;
  margin-right: 8px;

  svg {
    height: 32px;
    vertical-align: middle;
    width: 32px;

    [fill='none'] {
      stroke: $text-color-primary;
      stroke-width: 1.5;
    }

    path[fill]:not([stroke]) {
      fill: $text-color-primary;
    }
  }
}

.text {
  margin-top: 15px;

  @include mx-font-product-caption;

  a {
    color: inherit;
    border-bottom: 1px solid;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
      color: $black;
      border-color: $black;
    }
  }
}

.underlined {
  .container {
    max-width: 880px;
  }

  .point {
    padding: 40px 30px;

    @include max-screen(767px) {
      padding: 35px 30px 25px;
    }
  }

  .titleContainer {
    justify-content: center;
    @include max-screen(767px) {
      padding-left: 0;
      text-align: center;
    }
  }

  .title {
    border-bottom: 1px solid $mx-color-grey-2;
    @include button-cta-font;
    text-transform: uppercase;
    color: $text-color-secondary;
  }

  .text {
    @include body-font-2;
    text-align: center;
    color: $text-color-primary;
    margin-top: 10px;
  }
}

.big {
  padding: 30px 0;
  @include max-screen(767px) {
    padding: 0;
  }

  .container {
    max-width: 1220px;
  }

  .row {
    @include max-screen(992px) {
      padding: 0 10px;
    }
    @include max-screen(767px) {
      padding: 0px;
    }
  }

  .point {
    @include max-screen(767px) {
      padding: 25px 15px;
    }
  }

  .icon {
    margin-right: 8px;

    svg {
      height: 30px;
      width: 30px;

      circle:not([stroke]) {
        stroke: none;
      }

      :not([stroke-width]) {
        stroke-width: 1.5;
      }
    }
  }

  .titleContainer {
    @include max-screen(767px) {
      text-align: left;
      padding-left: 0;
    }

    .title {
      @include body-font-3;
    }
  }

  .text {
    margin-top: 22px;
    @include body-font-2;

    @include max-screen(992px) {
      @include body-font-4;
    }

    @include max-screen(767px) {
      @include body-font-2;
    }

    a {
      color: $text-color-secondary;
    }
  }
}
