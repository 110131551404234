@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  margin-top: 15px;

  @include max-screen(767px) {
    margin-top: 0;
  }
}

.title {
  @include h3-font-demi;
  padding: 30px 30px 15px;
  text-align: center;
}
