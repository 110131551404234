// Use the ones below
$accent-colour: #00a4b8;
$accent-colour-light-fallback: #06bbd1;
$accent-colour-dark-fallback: #006d7a;

$pale-gold: #c0b283;
$gold: #beb288;
$eggshell: #f1efed;
$sunrise: #fff59d;
$sand: #fff8bc;
$paper: #f4f4f4;
$light-pink: #faf7f3;
$ice: #d9d9d9;
$grey-sky: #bababa;
$grey: #9d9d9d;
$neutral: #8f8e8e;
$ash-light: #6b6a6b;
$ash: #646364;
$charcoal: #373737;
$white: #fff;
$black: #000;
$light-peach: #e6e0cd;
$light-beige: #faf6f2;
$seashell: #f8f1eb;
$warm-yellow: #e8b416;
$light-ice: #ebebeb;
$red: #d0021b;
$light-red: #db0000;
$dark-red: #450c1c;
$dark-turquoise: #06bbd1;
$light-petrol: #00a4b8;
$petrol-green: #006d7a;
$blue: #0000ff;
$dark-blue: #19325a;
$bay-leaf: #84a98d;
$neon-purple: #702bf8;
$deep-pink: #d8148f;
$new-yellow: #FFF8AD;
$dark-green: #04383D;
$light-blue: #C0E3E9;

$green: #7ec272;
$green-transparent: rgba(126, 194, 114, 0.7);
$pink: #f35860;
$pink-transparent: rgba(243, 88, 96, 0.7);
$light-white-transparent: rgba(255, 255, 255, 0.5);
$light-grey-transparent: rgba(22, 22, 22, 0.2);
$grey-transparent: rgba(22, 22, 22, 0.5);
$black-transparent: rgba(0, 0, 0, 0.5);

$text-color-primary: $charcoal;
$text-color-secondary: $ash;
$text-color-contrast: $white;
$text-color-inactive: $neutral;
$text-color-inactive-light: $grey-sky;
$link-color: $light-petrol;
$link-color-light: $pale-gold;

// Should not be used. Is only an interface to the designers naming convention
$mx-color-white: $white;
$mx-color-black: $black;
$mx-color-grey-1: $charcoal;
$mx-color-grey-2: $ash;
$mx-color-grey-3: $neutral;
$mx-color-grey-4: $grey-sky;
$mx-color-grey-5: $ice;
$mx-color-grey-6: $paper;

$mx-color-primary: $accent-colour;
$mx-color-primary-light: $accent-colour-light-fallback;
$mx-color-primary-dark: $accent-colour-dark-fallback;

$mx-color-secondary: $pale-gold;
$mx-color-secondary-light: $sunrise;
$mx-color-secondary-lighter: $sand;
$mx-color-light-green: #89c97d;
$mx-color-red: #d0021b;
$mx-color-green: #7ec272;

$mx-color-background-configurator: #e5e6e8;
$mx-color-white-transparent-1: rgba(255, 255, 255, 0.85);
$mx-color-white-transparent-2: rgba(255, 255, 255, 0.2);
$mx-color-white-transparent-3: rgba(255, 255, 255, 0.6);
$mx-color-grey-transparent-1: rgba(188, 187, 185, 0.8);
$mx-color-grey-transparent-2: rgba(249, 249, 249, 0.96);
$mx-color-grey-transparent-3: rgba(188, 187, 185, 0.6);

$mx-color-overlay-button-add: #ffffff;
$mx-color-overlay-button-erase: #6a6a6a;
$mx-color-overlay-button-select: #474747;
$mx-color-front-select-add: #89c97d;
$mx-color-front-select-erase: #f5636b;
$mx-color-front-select-select: #89c97d;
$mx-color-front-select-color: $mx-color-secondary-light;

$base: $mx-color-grey-3;
$minToolHeight: 59.5em;

// Paths
$images: '/img/';
$icons: $images + 'icons/';
$sprite: $icons + 'sprite-20161021.png';
