@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.discountBox {
  @include body-font-2;
  font-weight: 600;
  width: 53px;
  height: 29px;
  padding: 4px 8px;
  gap: 10px;
  opacity: 0px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid $black;
  margin-left: 12px;
}

.specialFilter {
  background-color: $black;
  color: $white;
}

.productPreview {
  @include body-font-4;
  border-radius: 50%;
  padding: 8px;
  width: 42px;
  height: 42px;
  margin: 0;
  background-color: $black;
  color: $white;
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;

  @include max-screen(767px) {
    @include mx-font-body-3;
    width: 35px;
    height: 35px;
    top: 15px;
    left: 15px;
  }
}
