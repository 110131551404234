@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  margin: 15px auto 50px;
  max-width: 1250px;
  @include max-screen(1280px) {
    margin: 15px 15px 50px;
  }

  @include max-screen(768px) {
    margin: 10px 20px 40px;
  }

  @include max-screen(767px) {
    margin: 10px 5px 30px;
  }
}

.furnitureTypefilters {
  @include max-screen(767px) {
    margin: 0;
  }
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.preview {
  width: 33.33%;
  position: relative;

  @include max-screen(1024px) {
    width: 50%;
  }
}

.featuredProduct {
  width: calc(100% - 33.33%);

  @include max-screen(1024px) {
    width: 100%;
  }
}

.pagination {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 2px;
  padding: 0 15px;

  @include max-screen(768px) {
    margin-top: 0;
  }

  @include max-screen(767px) {
    margin: 30px 5px 0px;
    padding: 0;
  }
}
