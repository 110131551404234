@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.filters {
  background-color: $light-beige;
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include max-screen(992px) {
    justify-content: space-between;
    padding: 15px 30px;
  }

  @include max-screen(767px) {
    padding: 0;
  }
}

.filter {
  @include body-font;
  margin: 0 2.3%;
  color: $black;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;

  @include max-screen(767px) {
    @include body-font-2;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid transparent;
  }

  &.filterActive,
  &:hover {
    .text {
      border-bottom-color: currentColor;
    }
  }

  &:hover {
    .text {
      color: $ash;
    }
  }

  &.filterActive {
    .text,
    .text:hover {
      color: $black;
      font-weight: 600;
    }
  }
}

.panel {
  width: 100%;
}
.panelHead {
  -webkit-tap-highlight-color: transparent;
  padding: 0px 10px;

  :global {
    .CollapsingPanel__headline {
      @include button-cta-font;
      color: $white;
      padding: 8px 9px;
    }
  }
}

.panelContent {
  background: $ash;
  padding: 8px 0;

  .filter {
    display: block;
    margin: 0;
    width: 100%;
    padding: 10px 20px;
  }
}

.chevron {
  top: 10px !important;
  right: 10px !important;
}
