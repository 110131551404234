@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.savedDesigns {
  @include max-screen(767px) {
    margin: 0;
  }
  :global {
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
}
