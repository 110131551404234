@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: flex;
  width: 66.67%; // 100% - 33.33%
  height: 387px;
  overflow: hidden;
  padding: 0 15px;
  margin: 15px 0;

  @include max-screen(1025px) {
    width: 50%;
  }

  @include max-screen(992px) {
    padding: 10px;
    width: 100%;
    height: fit-content;
  }
  @include max-screen(767px) {
    padding: 5px;
  }

  &.reverseImage {
    flex-direction: row-reverse;
    @include max-screen(767px) {
      flex-direction: column;
    }
  }

  @include max-screen(767px) {
    flex-direction: column;
    width: 100%;
  }

  .imageContainer {
    width: 50%;
    @include max-screen(767px) {
      height: 170px;
      width: 100%;
    }
    .image {
      height: 100%;
      width: 100%;
      &.desktopImage {
        @include max-screen(767px) {
          display: none;
        }
      }
      &.mobileImage {
        display: none;
        @include max-screen(767px) {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .contentContainer {
    width: 50%;
    display: flex;
    row-gap: 30px;
    flex-direction: column;
    justify-content: center;
    padding: 0 57px;
    background-color: $light-pink;
    @include max-screen(767px) {
      width: 100%;
      height: fit-content;
      row-gap: 10px;
      padding: 20px;
    }
    .title {
      @include h3-font-demi;
      @include max-screen(767px) {
        @include body-font-5;
      }
    }
    .link {
      @include button-cta-font;
      color: $charcoal;
      border-bottom: solid 1px $charcoal;
      width: fit-content;
      text-transform: uppercase;
      @include max-screen(767px) {
        @include caption-font;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
