@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.darkMode {
  color: $text-color-primary;
}

.lightMode {
  .title,
  .text {
    color: $white !important;
  }
}

.container {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 300px;
  position: relative;
  overflow: hidden;

  @include max-screen(767px) {
    min-height: 458px;
  }

  .imageWrapper,
  .imageWrapper picture {
    height: 100%;
    width: 100%;
  }

  .fitImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .contentContainer {
    padding-left: 104px;
    padding-right: 25%;
    max-width: 1170px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include max-screen(992px) {
      padding-left: 30px;
      padding-right: 45%;
      width: auto;
    }

    @include max-screen(767px) {
      justify-content: flex-end;
      margin: auto auto 0;
      padding: 30px 10px;
      width: 100%;
    }
  }

  .title {
    @include h3-font-demi;
    max-width: 383px;

    @include max-screen(767px) {
      @include h3-font-demi;
      max-width: 263px;
    }
  }

  .text {
    @include body-font-2;
    max-width: 383px;
    margin: 15px 0 20px;

    @include max-screen(767px) {
      @include body-font-2;
      max-width: 263px;
    }
  }

  .button {
    @include max-screen(767px) {
      text-align: center;
      a {
        text-align: center;
        width: 100%;
      }
    }
  }
}

.columnizedBlocks {
  &.container {
    max-width: unset;
    height: 100%;
    margin: auto;
  }

  .contentContainer {
    max-width: 100%;
    height: 100%;
    align-items: center;
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include max-screen(767px) {
      padding: 0;
    }

    .title {
      margin: 35px 0 25px;

      @include max-screen(767px) {
        margin: 30px 0 15px;
      }
    }

    .text {
      @include body-font-2;
      flex-grow: 1;
      max-width: 400px;
      margin: 0;

      @include max-screen(992px) {
        max-width: 100%;
        margin: 0 30px;
      }

      @include max-screen(767px) {
        margin: 0 20px;
      }
    }

    .button {
      display: block;
      margin-bottom: 40px;
    }
  }
}

.heroBlockTop {
  margin-top: 50px;

  @include max-screen(768px) {
    margin-top: 40px;
  }

  @include max-screen(767px) {
    margin-top: 0px;
  }
}

.heroBlockBottom {
  @include max-screen(767px) {
    padding: 5px 0 0;
  }
}
